.footer-bg {
  background-color: #f5f5f7;
}
.bg-img {
  background-image: url("../../../assets/images/mapLeft.png");
  background-repeat: no-repeat;
  background-size: 20%;
  margin-left: -2rem !important;
}
.py-10 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}
.fw-600{
  font-weight: 600;
}
.align-line{}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.contact-label {
  opacity: 1;
}
.custom-email {
  width: 90%;
  border-radius: 20px;
}
.social-icons {
  margin-left: 3rem;
  margin-right: 5rem;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}
.custom-email::placeholder {
  color: #3772b8;
}

.demo-wrap-footer {
  overflow: hidden;
  position: relative;
}

.demo-bg-footer {
  opacity: 1;
  position: absolute;
  left: -40%;
  top: -12%;
  width: 100%;
  height: 100%;
}
.demo-bg-footer-right {
  opacity: 1;
  position: absolute;
  right: -22%;
  top: 7%;
  width: 65%;
  height: 90%;
}

.demo-content-footer {
  position: relative;
}
.align-line-content{}

@media screen and (max-width: 450px) {
  .demo-bg-footer {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 48%;
  }
  .demo-bg-footer-right {
    opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    width: 50%;
    height: 48%;
  }
  .py-10 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sm-mt {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 420px) {
.align-line{
  display: contents !important;
}
}
@media (min-width:375px) and (max-width: 420px) {
  .align-line-content{
    /* display: contents !important;
     */
     margin-right: 22px;
  }
  }