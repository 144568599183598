.nav-link {
  color: black;
  font-size: 30px;
  padding: 0px !important;
  line-height: 1;
  cursor: pointer;
}

.nav-link:active,
.nav-link:hover,
.nav-link.active {
  color: #3872b8 !important;
  border-bottom: 2px solid #3872b8;
  padding: 0px !important;
  line-height: 1;
}
.lang, .lang:hover {
  border-bottom: 2px solid #020202;
  color: black;
  padding-bottom: 0px;
  cursor: pointer;
  font-size: 25px;
}
.navbar-light .navbar-nav .nav-link {
  color: black;
}
.bb{
  border-bottom: 2px solid #000000;
}
.bb:hover,.bb:active,.bb:focus{
  border-bottom: 2px solid #000000;
  color: black !important;
}

.fss-20{
  font-size: 20px !important;
}
.nv-position {
  margin-top: 2rem;
}
p {
  line-height: 1.25;
}
.mxx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
/* curtain menu*/

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 1.5rem;
  width: 100%;
  text-align: center;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: black;
  display: block;
  transition: 0.5s;
}

.overlay a:hover,
.overlay a:focus {
  color: black;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}
.custom-align {
  margin: 0 !important;
}
.custom-img {
  width: auto;
}
.overlay-m {
  margin-bottom: 3rem;
}
.custom-col{
  direction: inherit;
}
@media screen and (max-width: 450px) {
  .overlay {
    overflow-y: auto;
  }
  .overlay a {
    font-size: 30px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
  .nv-position {
    margin-top: 0px;
  }
  .nav-link {
    display: inline-block !important;
  }
  .custom-align {
    margin: auto !important;
    display: flex;
    justify-content: flex-end;
  }
  .custom-col{
    direction: ltr;
  }
  .custom-img {
    width: 100px;
  }
}
