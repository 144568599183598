.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@font-face {
  font-family: "Ara Hamah";
  src: url("./assets/font/Arahamah.otf");
}
@font-face {
  font-family: "Myriad-pro";
  src: url("./assets/font/MyriadPro-Regular.otf");
}
@font-face {
  font-family: "GESSTV";
  src: url("./assets/font/GESSTV.otf");
}

/* body{
  font-family: "Ara Hamah";
  font-style: normal;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-bg {
  background-image: linear-gradient(90deg, #eef2f9 65%, #fff 35%);
}
.app-bg-ar {
  background-image: linear-gradient(270deg, #eef2f9 65%, #fff 35%);
}

.fs-24 {
  font-size: 24px;
}
.afs-20{
  font-size: 20px;
    line-height: 2;
}

.fw-600 {
  font-weight: 600;
}
.cmb-5 {
  margin-bottom: 5rem;
}
img {
  max-width: 100%;
}

.para-w {
  width: 80%;
}
.f-ar {
  font-family: "GESSTV";
}
.f-en {
  font-family: "Ara Hamah";
}

@media screen and (max-width: 450px) {
  .app-bg,
  .app-bg-ar {
    background-image: linear-gradient(90deg, #eef2f9 100%, #fff 0%);
  }
  .cmb-5 {
    margin-bottom: 3rem;
  }
}
