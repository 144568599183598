.vision-bg {
    background: linear-gradient(to right,
        rgba(41, 66, 107, 1),
        rgba(58, 94, 146, 1),
        rgba(72, 119, 182, 1),
        rgba(124, 156, 203, 1),
        rgba(185, 202, 227, 1),
        rgba(215, 224, 239, 1),
        rgba(255, 255, 255, 1)
    );
    color:#FFFFFF

}
.vision-bg-ar {
  background: linear-gradient(to left,
      rgba(41, 66, 107, 1),
      rgba(58, 94, 146, 1),
      rgba(72, 119, 182, 1),
      rgba(124, 156, 203, 1),
      rgba(185, 202, 227, 1),
      rgba(215, 224, 239, 1),
      rgba(255, 255, 255, 1)
  );
  color:#FFFFFF

}

.cpx-5{
    padding-left:5rem !important;
    padding-right: 5rem !important;
}

.security-bg{
    background-image:url('../../../assets/images/security-bg.png') ;
    background-repeat: no-repeat;
    background: rgba(0,0,0,0.7);

}

.security-bg::before{
    opacity: 0.1;
    background: rgba(0,0,0,0.7);

}

.block-container {
    position: relative;
  }
  
  /* Bottom right text */
  .text-block {
    position: absolute;
    top: 10px;
    /* right: 20px; */
    color: #3772B8;
    padding-left: 20px;
    padding-right: 20px;
  }

  .block-container{
    font-size: 15px;
    line-height: 2.5;
  }
  .block-3{
    height: 160px;
  }
  .block-4{
    height: 210px;
  }

  .demo-wrap {
    overflow: hidden;
    position: relative;
  }
  .client-c{
    color: #3772B8;
  }
  .mbb-3{
    margin-bottom: 2rem !important;
  }
  .demo-bg {
    opacity: 1;
    position: absolute;
    left: 14%;
    top: 9%;
    width: 76%;
    height: 85%;
  }
  
  .demo-content {
    position: relative;
  }

.mtt-6{
    margin-top: 6rem;
}
.sm-m{
  margin-top: 0rem;
}
.mtt-5{
  margin-top: 5rem !important;
}
.img-width-service{
  width: 60%;
}
.ranking-width{
  width: 75%;
}
.pbb-5{
  padding-bottom: 5rem !important;
}
.ff-myraid{
  font-family: 'Myriad-pro';
}
.txt-justify{
  text-align: justify;
}
.col-mt{
  margin-top: 0rem;
}
.lh{
  line-height: 1.2;
}
.mbb-2{
  margin-bottom: 0rem;
}

.mt-s-3{
  margin-top: 1rem !important;
}

.m-s-b-3{
  margin-bottom: 0.5rem !important;
}

@media screen and (max-width: 450px) {
  /* .mt-s-3{
    margin-top: 0rem !important;
  } */
  .m-s-b-3{
    margin-bottom: 0rem !important;
  }
.cpx-5{
  padding-left:15px !important;
  padding-right: 15px !important;
}
.mtt-6{
  margin-top: 1rem;
}
.sm-m{
  margin-top: 1rem;
}
.ib-4{
  height: 150px;
}
.demo-bg {
  opacity: 0.2;
  position: absolute;
  left: 0%;
  top: 27%;
  width: 100%;
  height: 50%;
}
.txt-justify{
  text-align: left;
}
.col-mt{
  margin-top: 3rem;
}
.lh{
  line-height: 0.75;
}
.ranking-width{
  width: 40%;
}
.ranking-w2{
  width: 45%;
}
.pbb-5{
  padding-bottom: 2rem !important;
}
.mbb-2{
  margin-bottom: 2rem;
}
}