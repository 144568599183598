.img-col{
    padding: 0;
    right: 0rem;
    text-align: center;
    top: -1rem;
}
.img-col-ar{
    padding: 0;
    text-align: center;
    top: -1rem;
}
.pll-5{
    padding-left: 5rem;
}
.prr-5{
    padding-right: 5rem;
}
.title{
   color: #24416E

}
.text-desc{
    color: #3772B8    ;
}

.fs-3{
    font-size: 3rem;
}
.w-90{
    width: 75%;
}
@media screen and (max-width: 575px) { 
.img-col-ar{
    margin-top: 2rem;
    order: 0;
    padding: 0;
    text-align: center;
    top:0rem
}
.img-col{
    margin-top: 2rem;
    order:0;
    padding: 0;
    right: 0rem;
    top :0rem;
    text-align: center;
}
.col-order{
    order:1
}
}
@media screen and (max-width: 450px) { 

    .pll-5{
        padding-left: 15px !important;
    }
    .prr-5{
        padding-right: 15px !important;
    }
    .img-col{
        margin-top: 2rem;
        order:0;
        padding: 0;
        right: 0rem;
        text-align: center;
    }
    .img-col-ar{
        margin-top: 2rem;
        order: 0;
        padding: 0;
        text-align: center;
    }
    .col-order{
        order:1
    }
    .w-90{
        width: 90%;
    }
}